import React from 'react';

import './MenuItem.css';

const MenuItem = () => (
  <div>
    MenuItem
  </div>
);

export default MenuItem;
